import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import Jobs from './Jobs';
import JobDetails from './JobDetails';
import JobErrors from './JobError';
import TaskDetail from "../Metadata/Detail/TaskDetail";
import AddOperation from "../Metadata/Operation/AddOperation";
import TaskList from "../Metadata/List/TaskList";
import MetadataList from "../Metadata/List/MetadataList";
import ManagementAPI from '../Common/API/managementAPI';
import ResponseErrorHandler from '../Common/responseErrorHandler';
import { MessageActions } from '../Common/Utils/Message';
import _ from "lodash";
import RepoConfig from '../Metadata/List/RepoConfig';
import MetadataDetail from '../Metadata/Detail/MetadataDetail';
import Myself from "../Metadata/List/Myself";
import Concepts from '../Metadata/Detail/concepts';
import Dashboard from '../Metadata/Detail/dashboard';
import HomePage from './homePage';

function Taxonomy(props) {
  const { roles, userId } = props;
  const [jobs, setJobs] = useState([]);
  const defaultStart = new Date();
  defaultStart.setDate(defaultStart.getDate() - 15);
  const defaultEnd = new Date();

  const [startDate, setStartDate] = useState(defaultStart);
  const [endDate, setEndDate] = useState(defaultEnd);

  const [isLoading, setIsLoading] = useState(true);
  const [taxonomyNames, setTaxonomyNames] = useState([]);

  const clearState = () => {
    setIsLoading(false);
    setJobs([]);
  }

  const setJobsStates = (jobs, names, loading) => {
    setIsLoading(loading);
    setTaxonomyNames(names)
    setJobs(jobs);
  }

  const getLastSuccessIndex = (jobs) => {
    let lastSuccessIndex = -1;
    if (jobs && jobs.length > 0) {
      jobs = _.orderBy(jobs, ['updatedAt', 'startedAt'], ["desc"]);
      lastSuccessIndex = _.findIndex(jobs, job => { return job && (job.jobStatus === "success" || job.jobStatus === "partialSuccess") });
    }
    return lastSuccessIndex;
  }

  useEffect(() => {
    setIsLoading(true);
    const jobsPromise = ManagementAPI.getJobsBetweenTimeRange(startDate, endDate);
    const namePromise = ManagementAPI.getNames(true);
    Promise.all([jobsPromise, namePromise]).then(res => {
      MessageActions.clear();
      let jobs = res[0].data;
      let lastSuccessIndex = getLastSuccessIndex(jobs);
      if (lastSuccessIndex !== -1) {
        // If there is success job during current time range, just move this last success job on top
        jobs.splice(0, 0, jobs.splice(lastSuccessIndex, 1)[0]);
        setJobsStates(jobs, res[1].data, false);
      } else {
        // Otherwise we have to call api to get the last success job put it on top
        if (jobs && jobs.length > 0) {
          let jobId = _.get(jobs, [0, 'jobId']);
          if (jobId) {
            ManagementAPI.getLastSuccessJob(jobId).then(lsJob => {
              jobs.unshift(lsJob.data);
              setJobsStates(jobs, res[1].data, false);
            });
          }
        } else {
          setJobsStates(jobs, res[1].data, false);
        }
      }

    }).catch(
      error => {
        ResponseErrorHandler(error);
        clearState();
      }
    )
  }, [startDate, endDate]);

  return (
    <div id="taxonomy">
      <Switch>
        <Route
          exact
          path={'/taxonomy'}
          render={props => (
            <Jobs {...props}
              jobs={jobs}
              roles={roles}
              isLoading={isLoading}
              taxonomyNames={taxonomyNames}
              setIsLoading={setIsLoading}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          )}
        />
        <Route
          path={`/taxonomy/detail/:toId/:name?`}
          render={props => (
            <JobDetails {...props} jobs={jobs} taxonomyNames={taxonomyNames} />
          )}
        />
        <Route
          path={`/taxonomy/error/:failureReport`}
          render={props => (
            <JobErrors {...props} jobs={jobs} taxonomyNames={taxonomyNames} />
          )}
        />
        <Route exact path={`/tasks`} render={() => <TaskList />} />
        <Route exact path={`/tasks/op`} render={() => <AddOperation userId={userId} />} />
        <Route
          exact
          path={`/tasks/:id`}
          render={(props) => <TaskDetail {...props} />}
        />
        {/* repoId here is optional, used to filter bypass policyRepos, you can also pass other params to filter, this is just an example*/}
        <Route exact path={`/repos/repoconfig/:routeRepoOwner?/:routeRepoName?`} render={() => <RepoConfig />} />
        <Route exact path={[`/metadata/detail`, `/metadata/detail/:id`,`/metadata/detail/:id/:locale`]} render={() => <MetadataDetail />} />
        <Route exact path={[`/metadata/query`, `/metadata/query/:taskId`]} render={() => <MetadataList userId={userId}/>} />
        <Route exact path={`/myself`} render={() => <Myself />} />
        <Route exact path={`/concepts`} render={() => <Concepts />} />
        <Route exact path={`/dashboard`} render={() => <Dashboard />} />
        <Route exact path={['/', `/index`]} render={() => <HomePage />} />
      </Switch>
    </div>
  );
}

export default Taxonomy;
