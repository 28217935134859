import axios from "axios";
import { configs } from "../Config";
import {
  acquireManagementTokenSilent,
  acquireManagementTokenRedirect,
} from "../Auth/auth-utils";
import _ from "lodash";
const baseURL = configs.TaxonomyApp.endpoint;

const instance = axios.create({ baseURL: baseURL });

instance.interceptors.request.use(function (config) {
  return acquireManagementTokenSilent().then((data) => {
    if (data) {
      config.headers = {
        ...config.headers,
        "Cache-Control": "no-cache",
        Authorization: `Bearer ${data.accessToken}`,
      };
    }
    return config;
  });
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (401 === _.get(error, "response.status", null)) {
      if (
        _.startsWith(
          _.get(error, "response.config.baseURL"),
          configs.TaxonomyApp.endpoint
        )
      ) {
        acquireManagementTokenRedirect();
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
