import { UserAgentApplication, Logger, LogLevel } from 'msal';
import getOrigin from './getOrigin';
import { configs } from '../Config';

// workarround to the issue: login without redirection or login loop
// https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1301
window.opener = null;

export const requiresInteraction = (errorMessage) => {
  if (!errorMessage || !errorMessage.length) {
    return false;
  }

  return (
    errorMessage.indexOf('consent_required') > -1 ||
    errorMessage.indexOf('interaction_required') > -1 ||
    errorMessage.indexOf('login_required') > -1 ||
    errorMessage.indexOf('null_or_empty_id_token') > -1 ||
    errorMessage.indexOf('user_login_error') > -1
  );
};

export const fetchMsGraph = async (url, accessToken) => {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.json();
};

export const acquireToken = async (request, redirect) => {
  return msalApp.acquireTokenSilent(request).catch((error) => {
    // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
    // due to consent or interaction required ONLY
    if (requiresInteraction(error.errorCode)) {
      return redirect ? msalApp.acquireTokenRedirect(request) : msalApp.acquireTokenPopup(request);
    } else {
      if (error.errorCode === 'block_token_requests') {
        console.log('Non-interactive error:', error.errorCode);
      } else {
        console.error('Non-interactive error:', error.errorCode);
      }
    }
  });
};

export const acquireTokenRedirect = async (request, redirect) => {
  return redirect ? msalApp.acquireTokenRedirect({ ...request }) : msalApp.acquireTokenPopup(request);
};

export const acquireManagementTokenSilent = async () => {
  return await acquireToken({ scopes: MANAGEMENT_ACCESS_SCOPES }, true);
};

export const acquireManagementTokenRedirect = () => {
  return acquireTokenRedirect({ scopes: MANAGEMENT_ACCESS_SCOPES }, true);
};

export const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ') > -1;
  const msie11 = ua.indexOf('Trident/') > -1;

  return msie || msie11;
};

export const MANAGEMENT_ACCESS_SCOPES = [`api://${configs.TaxonomyApp.clientId}/user_impersonation`];

export const LOGIN_SCOPES = ['profile'];

export const msalApp = new UserAgentApplication({
  auth: {
    clientId: configs.TaxonomyApp.clientId,
    authority: configs.TaxonomyApp.authority,
    redirectUri: getOrigin()
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: isIE(),
  },
  system: {
    navigateFrameWait: 0,
    logger: new Logger((logLevel, message) => {
      console.log(message);
    }, {
      level: LogLevel.Verbose,
      piiLoggingEnabled: true
    })
  },
});
